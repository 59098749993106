<template>
  <div class="works">
    <left-menu current="publish" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item to="/incomeList">提现申请</el-breadcrumb-item>
            <el-breadcrumb-item>提现详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" @click="$router.back()">
            &lt;返回</el-link
          ></el-col
        >
      </el-row>
      <el-divider></el-divider>

        <div v-if="cashOutOrder.status == 0">
          <Title name="提现申请已提交，请等待平台审核打款" fontsize="18px" />
          <div class="tips">审核时间预计3个工作日</div>
        </div>
        <div v-if="cashOutOrder.status == 1">
          <Title name="提现成功" fontsize="18px" />
          <div class="tips">您的提现申请已成功~</div>
        </div>
      <div class="stepsBox" v-if="cashOutOrder.status == 0 || cashOutOrder.status == 1">
        <el-steps direction="vertical" :active="stepActive">
        <el-step title="提现申请">
          <img src="@/assets/appayIng.png" slot="icon" class="stepIcon" />
        </el-step>
        <el-step title="到账成功">
          <img v-if="stepActive == 1" src="@/assets/successIng.png" slot="icon"  class="stepIcon" />
          <img v-if="stepActive == 2" src="@/assets/successed.png" slot="icon"  class="stepIcon" />
        </el-step>
      </el-steps>
      </div>

      <div class="stepsBoxError" v-if="cashOutOrder.status == 2">
        <el-steps direction="vertical" :active="1">
        <el-step title="提现失败" :description="`原因：${cashOutOrder.desp}`">
          <img src="@/assets/error.png" slot="icon" class="stepIcon" />
        </el-step>
      </el-steps>
      </div>

      <div class="descriptionsBox">
    <el-descriptions :contentStyle="{'color':'#666666','fontSize':'14px'}" :labelStyle="{'width':'90px','color':'#999999','fontSize':'12px'}" :column="1">
    <el-descriptions-item label="提现至">{{paymentModeType}}</el-descriptions-item>
    <el-descriptions-item label="提现申请金额">￥{{(cashOutOrder.cashOutMoney+cashOutOrder.personalTax).toFixed(2)}}</el-descriptions-item>
    <el-descriptions-item label="实际到账金额">￥{{(cashOutOrder.cashOutMoney).toFixed(2)}}</el-descriptions-item>
    <el-descriptions-item label="付款方">{{cashOutOrder.paymentCompany}}</el-descriptions-item>
    <el-descriptions-item label="提交时间">{{$timestampToString(cashOutOrder.insetTime)}}</el-descriptions-item>
    <el-descriptions-item label="提现单号">{{cashOutOrder.orderId}}</el-descriptions-item>
</el-descriptions>
      </div>

      <div class="cTitle"><span>关联账单</span></div>
      <el-divider></el-divider>

      <el-table
        :data="tableData"
        :header-cell-style="headerStyle"
        style="width: 100%; margin-bottom: 48px; margin-top: 20px"
      >

        <el-table-column prop="title" label="账单名称" />
        <el-table-column prop="typeString" label="账单分类" />
        <el-table-column prop="totalMoney" label="账单金额" />
        <el-table-column prop="createTime" label="入账时间">
          <template slot-scope="{ row }">
            <span>{{ $timestampToString(row.createTime) }}</span>
          </template>
        </el-table-column>

      </el-table>
      <pagination
        v-if="total > 0"
        :current="params.pageNum"
        :total="total"
        :pageSize="params.pageSize"
        @change="onPageChange"
        @pageSizeChange="onPageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu.vue";
import { cashOutRecordDetail,dictInfo } from "../api/index";
import Pagination from "../components/Pagination.vue";
import Title from "@/components/withdrawal/Title.vue";
const PAGESIZE = 10;
export default {
  components: { LeftMenu, Title, Pagination },
  data() {
    return {
      //详情数据
      cashOutOrder:{
        status:'',//状态
        orderId:'',//提现单号
        paymentMode:'2',//提现方式
        withdrawState:'',//提现是否成功(0失败;1成功)
        desp:'',//审核备注
        paymentCompany:'',//付款公司名称
        insetTime:'',//提现单申请时间
        userBankId:'',//提现银行卡id
        cashOutMoney:0,//提现金额
        totalMoney:0,//总金额
        personalTax:0,//个人所得税
        cashOutDoneTime:'',//预计到帐时间

      },
      typeData: [],
      //列表数据
      tableData: [],
      //列表请求参数
      params: {
        pageNum: 1,
        pageSize: PAGESIZE,
        id: ""
      },
      //表头样式
      headerStyle: {
        background: "#F8F8F8",
        color: "#333",
        fontSize: "14px",
        fontWeight: "400",
        borderBottom: "none",
      },
      //下拉框默认展示近三个月
      filterValue: 3,
      //数据总条数
      total: 0,
      //时间段筛选的值
      pickerValue: [],
    //   银行卡数据
    appUserBank:{}
    }
  },

  created() {
    dictInfo({ codes: ["10016"] })
      .then((res) => {
        this.typeData = res.data;
      })
      .finally(() => {
        this.getDetail()
      });
    this.params.id = this.$route.params.id
    // this.getList();

  },
  computed:{
    stepActive(){
      let { status } = this.cashOutOrder
      if(status == -1){
        //删除
        return 0
      }else if(status == 0){
        //待审核
        return 1
      }else if(status == 1){
        //审核通过
        return 2
      }else if(status == 2){
        //审核不通过
        return 3
      }else{
        return ''
      }
    },

    //提现方式
    paymentModeType(){
      let t = ''
      let {paymentMode}  = this.cashOutOrder
      let{bankName,bankCard=[]}= this.appUserBank
      if(paymentMode == 0){
        t = '支付宝'
      }
      if(paymentMode == 1){
        t = '微信'
      }
      console.log(bankCard.length)
      if(paymentMode == 2){
        if(bankCard.length>4){
          t = `${bankName}(${bankCard.substring(bankCard.length-4)})`
        }else{
         t = '银行卡(xxxx)'
        }
      }
      return t
    }
  },
  methods: {
    //提现状态
    statusTxt(status) {
      let txt = "";
      switch (status) {
        case -1:
          txt = "已删除";
          break;
        case 0:
          txt = "提现中";
          break;
        case 1:
          txt = "提现成功";
          break;
        case 2:
          txt = "提现失败";
          break;
        default:
          break;
      }
      return txt;
    },

    //监听下拉款
    // selectChange(e) {
    //   if (e !== 1) {
    //     this.pickerValue = [];
    //     this.params.startTime = this.formatDate(e)[0];
    //     this.params.endTime = this.formatDate(e)[1];
    //     this.getList();
    //   }
    // },

    //获取详情数据
    getDetail(){
      cashOutRecordDetail(this.params).then(res=>{
        let {list,cashOutOrder,total,appUserBank} = res.data
        this.total = total
        this.appUserBank=appUserBank
        list.forEach((item) => {
          this.typeData.forEach((t) => {
              if (t.label == item.type) {
                item.typeString = t.value;
              }
            });
        });
        this.tableData = list
        if(cashOutOrder){
          this.cashOutOrder = cashOutOrder
        }
      })
    },

    //获取提现记录
    // getList() {
    //   cashOutRecord(this.params).then((res) => {
    //     let { total, list } = res.data;
    //     this.total = total;
    //     this.tableData = list;
    //   });
    // },

    //近几月
    // formatDate(month) {
    //   const end = new Date();
    //   const start = new Date();
    //   start.setTime(start.getTime() - 3600 * 1000 * 24 * month * 30);
    //   let startString = `${start.getFullYear()}-${
    //     start.getMonth() + 1
    //   }-${start.getDate()} ${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`;
    //   let endString = `${end.getFullYear()}-${
    //     end.getMonth() + 1
    //   }-${end.getDate()} ${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;
    //   return [startString, endString];
    // },

    onPageChange(e) {
      this.params.pageNum = e.current;
      this.getDetail();
    },

    onPageSizeChange(ps) {
      this.params.pageNum = 1;
      this.params.pageSize = ps;
      this.getDetail();
    },

    // //选择时间段
    // datePickerChange(e) {
    //   this.params.startTime = `${e[0]} 00:00:00`;
    //   let end = new Date();
    //   this.params.endTime = `${end.getFullYear()}-${
    //     end.getMonth() + 1
    //   }-${end.getDate()} ${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;
    //   this.getList();
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/works.scss";
.main {
  box-sizing: border-box;
  ::v-deep .el-divider {
    background-color: #e5e5e5;
  }
  ::v-deep .el-step__line{
    height: 48px;
    top: 37px;
  }
  ::v-deep .is-finish{
    font-size: 16px;
    color: #00B579;
    font-weight: normal;
  }
  ::v-deep .is-process{
    font-size: 16px;
    color: #CCCCCC;
    font-weight: normal;
  }
  .tips{
    color: #999999;
    font-size: 14px;
    margin-top: 6px;
  }
  .stepsBox{
    height: 130px;
    margin-top: 40px;
    .stepIcon{
      width: 30px;
      height: 30px;
    }
    ::v-deep .el-step__description{
      font-size: 14px;
      color: #999999;
    }
  }
  .stepsBoxError{
    margin-top: 40px;
    .stepIcon{
      width: 30px;
      height: 30px;
    }
    ::v-deep .el-step__main{
      padding-left: 16px;
    }
    ::v-deep .el-step__description{
      font-size: 14px;
      color: #999999;
    }
    ::v-deep .el-step__title{
      color: #333333;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .descriptionsBox{
    margin-top: 40px;
    background: #F9F9F9;
    padding: 25px 19px;
    ::v-deep .el-descriptions__body{
      background-color: #F9F9F9;
    }
  }
  .cTitle{
    color: #333333;
    font-size: 16px;
    margin-top: 40px;
  }

}
</style>
